/**
 * Copyright (C) Schweizerische Bundesbahnen SBB, 2018.
 */

import {Injectable} from '@angular/core';
import {PartnerApi, PartnerStammdatenApiModel} from 'cargo-hub-ui-customer-api';
import {Observable} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {ZkkPartner} from '../../model/partner-helper';

export enum BeteiligterType {
  absender,
  empfaenger,
  frachtzahlerVersand,
  frachtzahlerEmpfang,
  erfasser,
  endKunde,
  beobachter
}

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor(private partnerApi: PartnerApi) {}

  getPartnerById(partnerId: string, appendZkkHeader: boolean = false): Observable<PartnerStammdatenApiModel> {
    if (appendZkkHeader) {
      this.partnerApi.defaultHeaders = this.partnerApi.defaultHeaders.set('X-Zkk', partnerId);
      return this.partnerApi.getPartnerStammdaten(partnerId).pipe(
        finalize(() => {
          this.partnerApi.defaultHeaders = this.partnerApi.defaultHeaders.delete('X-Zkk');
        })
      );
    }
    return this.partnerApi.getPartnerStammdaten(partnerId);
  }

  getPartnersBySearch(search: string): Observable<Array<ZkkPartner>> {
    return this.partnerApi.getStammdatenPartner(search).pipe(
      map(apiPartners => (apiPartners ? (apiPartners as ZkkPartner[]) : [])) // handle 204 gracefully
    );
  }

  getAllPartners(): Observable<Array<ZkkPartner>> {
    return this.partnerApi.getStammdatenPartner().pipe(
      map(apiPartners => (apiPartners ? (apiPartners as ZkkPartner[]) : [])) // handle 204 gracefully
    );
  }
}
